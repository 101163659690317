@import "_vars.scss";
@import "~@ng-select/ng-select/themes/material.theme.css";

[hidden] {
    display: none !important;
}

body, html {
    color: $darkColor;
    font-family: 'Open Sans';
}
body {
    margin-bottom: 64px;
}
.navbar-inverse {
    background-color: $darkColor;
}
.navbar-brand {
    background-image: url('../assets/logo-white.svg');
    background-size: auto 60%;
    background-repeat: no-repeat;
    background-position: 15px center;
    padding-left: 160px;
}
.curpoint {
    cursor: pointer;
}
.loginform {
    width: 500px;
    margin: 100px auto;
    padding: 50px;
    background-color: $darkColor;
    border-radius: 25px;
    color: #FFF;
    h1 {
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 30px;
        text-align: center;
    }
    button {
        color: $contrastColor;
        display: block;
        float: right;
        border: 1px solid $contrastColor;
        border-radius: 5px;
        margin-top: 25px;
        background: none;
        padding: 10px 15px;
    }
    .message {
        margin-top: 15px;
        color: $contrastColor;
        text-align: center;
    }
    .lost {
        color: $contrastColor;
        margin-top: 35px;
        display: block;
        float: left;
    }
    label {
        margin-top: 10px;
        width: 100%;
        span {
            width: 150px;
            display: block;
            float: left;
            line-height: 32px;
            font-weight: normal;
        }
        input {
            width: calc(100% - 150px);
            color: #FFF;
            padding: 5px 10px;
            background-color: lighten($darkColor, 15);
            border: 1px solid $contrastColor;
            border-radius: 5px;
        }
    }
}
.flright {
    float: right;
}
.txtright {
    text-align: right;
}
.txtbold {
    font-weight: bold;
}
